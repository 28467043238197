<template>
  <div class="main-content" id="content">
    <h3 class="marginBottom20">
      {{ `${type === "add" ? "新增" : type === "edit" ? "编辑" : "查看"}视频` }}
    </h3>
    <!-- <el-card style="padding-left: 50px"> -->
    <el-form
      ref="form"
      :model="form"
      label-position="left"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item label="视频名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入"
          style="width: 400px"
          maxlength="50"
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="权益分类" :span="8" prop="equityClassifyIds">
        <el-checkbox-group v-model="form.equityClassifyIds">
          <el-checkbox
            v-for="item in equityClassifyList"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
        <!-- <el-select
          v-model="form.equityClassifyIds"
          clearable
          style="width: 400px"
          :disabled="disabled"
        >
          <el-option
            v-for="item in equityClassifyList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="视频分类" :span="8" prop="classifyId">
        <el-select
          v-model="form.classifyId"
          clearable
          style="width: 400px"
          :disabled="disabled"
        >
          <el-option
            v-for="item in classifyList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="主图" :span="8" prop="mainImage">
        <FormImgUpload
          :url="form.mainImage"
          @upload="uploadImg"
          :limit="1"
          :maxSize="20"
          :accept="'.png,.jpg,.jpeg,.svg,.tiff'"
          :multiple="true"
          :disabled="disabled"
        />
        <div style="color: #c0c1c3">
          建议尺寸：330px*220px，图片格式：.png,.jpg,.jpeg,.svg,.tiff
        </div>
      </el-form-item>
      <!-- <div class="flex">
        <div>
          <span class="width70" style="display: inline-block">视频名称 </span>
          <el-input
            v-model="form.fileName"
            placeholder="请输入"
            style="width: 400px"
            maxlength="20"
            :disabled="disabled"
          ></el-input>
        </div>
        <div class="flex">
          <span class="width70" style="display: inline-block">视频</span>
          <FormVideoUpload
            :url="form.fileUrl"
            @upload="uploadVideo"
            :limit="1"
            :maxSize="200"
            :accept="'.mp4,.webm,.avi'"
            :multiple="true"
            :disabled="disabled"
          />
          <span style="color: #c0c1c3">
            最大200M，图片格式：.mp4,.webm,.avi
          </span>
        </div>
      </div> -->
      <el-form-item label="上传视频" prop="details">
        <div
          class="flex video_box"
          v-for="(item, index) in form.details"
          :key="index"
        >
          <div>
            <FormVideoUpload
              :url="item.fileUrl"
              @upload="uploadVideo"
              :limit="1"
              :maxSize="500"
              :accept="'.mp4,.webm,.avi'"
              :multiple="true"
              :disabled="disabled"
              :indexof="index"
            />
            <span style="color: #c0c1c3">
              最大500M，视频格式：.mp4,.webm,.avi
            </span>
          </div>
          <div>
            <span class="video_span" style="display: inline-block"
              >{{ index === 0 ? "名称" : "" }}
            </span>
            <el-input
              v-model="item.fileName"
              placeholder="请输入"
              style="width: 400px"
              maxlength="50"
              :disabled="disabled"
            ></el-input>
          </div>
          <i
            class="el-icon-circle-plus video_icon"
            size="30"
            @click="addVideo"
            v-if="index === form.details.length - 1 && !disabled"
          ></i>
          <i
            class="el-icon-delete video_icon"
            size="30"
            @click="delVideo(index)"
            v-if="form.details.length > 1 && !disabled"
          ></i>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button @click="revert">返回</el-button>
        <el-button type="primary" @click="submit" v-if="!disabled"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
    <!-- </el-card> -->
  </div>
</template>

<script>
import {
  videoListClassify,
  videoManageAdd,
  videoManageUpdate,
  videoManageView,
  videoEquityClassifyList,
} from "@/api/video/manage";
import FormEditor from "@/components/formComponents/formEditor";
import dayjs from "dayjs";
import FormFileUpload from "@/components/formComponents/formFileUpload";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import FormVideoUpload from "@/components/formComponents/formVideoUpload";

export default {
  name: "create",
  components: {
    FormEditor,
    FormFileUpload,
    FormImgUpload,
    FormVideoUpload,
  },
  data() {
    return {
      classifyList: [],
      id: this.$route.query.id,
      disabled: this.$route.query.type === "view",
      type: this.$route.query.type,
      form: {
        classifyId: "",
        equityClassifyIds: [],
        name: "",
        mainImage: [],
        details: [{ fileUrl: [], name: "", fileName: "", durationNum: "" }],
      },
      //校验规则
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        classifyId: [{ required: true, message: "请选择", trigger: "blur" }],
        equityClassifyIds: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        mainImage: [
          {
            required: true,
            message: "请上传",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error("请上传"));
              } else {
                callback();
              }
            },
          },
        ],
        details: [
          {
            required: true,
            message: "请上传",
            trigger: "blur",
          },
          // {
          //   validator: (rule, value, callback) => {
          //     if (value.length === 0) {
          //       callback(new Error("请上传"));
          //     } else {
          //       value.map((item) => {
          //         if (item.fileUrl == "") {
          //           callback(new Error("请上传图片"));
          //         } else if (item.fileName == "") {
          //           callback(new Error("请输入名称"));
          //         } else {
          //           callback();
          //         }
          //       });
          //     }
          //   },
          // },
        ],
      },
      loadingSubmit: false,
      equityClassifyList: [], //权益分类
    };
  },
  mounted() {
    this.getClassifyList();
    this.getEquityList();
    if (this.id) {
      this.getView();
    }
  },
  methods: {
    async getView() {
      if (this.id) {
        const { data } = await videoManageView(this.id);
        this.form = {
          ...data,
          mainImage: data.mainImage.split(","),
          details: data.details.map((item) => {
            return {
              ...item,
              fileUrl: item.fileUrl.split(","),
            };
          }),
          equityClassifyIds: data.equityClassifyIds.split(","),
        };
      }
    },

    async submit() {
      if (this.loadingSubmit) {
        return;
      }
      try {
        this.form.details.map((item) => {
          if (item.fileUrl.length === 0) {
            throw "请上传视频";
          }
          if (item.fileName === "") {
            throw "请输入视频名称";
          }
        });
        const selectedItems = this.equityClassifyList.filter((item) =>
          this.form.equityClassifyIds.includes(item.id)
        );

        // 根据 classifyList 中的 index 字段对选中的项目进行排序
        selectedItems.sort((a, b) => a.index - b.index);
        let newVal = {
          ...this.form,
          details: this.form.details.map((item) => {
            return {
              ...item,
              fileUrl: item.fileUrl.join(","),
            };
          }),
          mainImage: this.form.mainImage.join(","),
          equityClassifyIds: selectedItems.map((item) => item.id).join(","),
        };

        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loadingSubmit = true;
            if (this.type == "add") {
              videoManageAdd({
                ...newVal,
              })
                .then((res) => {
                  // 获取数据成功后的其他操作
                  if (res.code == 200) {
                    this.loadingSubmit = false;
                    this.$router.go(-1);
                  } else {
                    this.loadingSubmit = false;
                    // this.$message.error(res.msg);
                  }
                })
                .catch((err) => {
                  // loading.close();
                  // this.$message.error(err.msg);
                  this.loadingSubmit = false;
                });
            } else {
              videoManageUpdate({
                ...newVal,
                id: this.id,
              })
                .then((res) => {
                  // 获取数据成功后的其他操作
                  if (res.code == 200) {
                    this.loadingSubmit = false;
                    this.$router.go(-1);
                  } else {
                    this.loadingSubmit = false;
                    this.$message.error(res.msg);
                  }
                })
                .catch((err) => {
                  // loading.close();
                  // this.$message.error(err.msg);
                  this.loadingSubmit = false;
                });
            }
          }
        });
      } catch (e) {
        this.$message.error(e);
      }
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.content = e;
    },
    //视频上传
    uploadVideo(e, name, size, duration, index) {
      if (Array.isArray(e)) {
        this.form.details = this.form.details.map((item, indexs) => {
          if (indexs == index) {
            return {
              ...this.form.details[index],
              durationNum: duration,
              // fileName: name,
              fileUrl: [...e],
            };
          } else {
            return item;
          }
        });
      } else {
        this.form.details = this.form.details.map((item, indexs) => {
          if (indexs === index) {
            return new Promise((resolve) => {
              const video = document.createElement("video");

              video.onloadedmetadata = () => {
                const duration = video.duration;
                console.log(duration, "打印视频时长");
                video.remove();
                resolve({
                  ...item,
                  durationNum: duration,
                  fileName: name,
                  fileUrl: [e],
                });
              };

              video.src = e;
            });
          } else {
            return item;
          }
        });
      }
      Promise.all(this.form.details).then((updatedDetails) => {
        this.form.details = updatedDetails;
      });
      console.log(this.form.details, "this.form.details");

      // this.form.fileName = name;
      // this.form.sizeNum = size;
      // this.form.durationNum = duration;
    },
    //图片上传
    uploadImg(e) {
      if (Array.isArray(e)) {
        this.form.mainImage = [...e];
      } else {
        this.form.mainImage = [...this.form.mainImage, e];
      }
    },
    //视频分类
    getClassifyList() {
      videoListClassify({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.classifyList = res.data;
        }
      });
    },
    // 权益分类
    getEquityList() {
      videoEquityClassifyList({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.equityClassifyList = res.data;
        }
      });
    },
    revert() {
      this.$router.go(-1);
    },
    // 添加视频
    addVideo() {
      this.form.details.push({ durationNum: "", fileName: "", fileUrl: [] });
    },
    delVideo(index) {
      this.form.details.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.main-content {
  padding-left: 30px;
  padding-right: 40%;
}
/deep/.el-icon-circle-plus:before {
  content: "\e7a0";
  font-size: 26px;
}
/deep/.el-icon-delete:before {
  content: "\e6d7";
  font-size: 26px;
}
/deep/.el-icon-zoom-in:before {
  content: "\e777";
  font-size: 26px;
}

.video_box {
  /* border: 1px solid #ddd; */
}
.video_icon {
  cursor: pointer;
  margin-top: 0.5%;
  margin-left: 10px;
}
.video_span {
  width: 70px;
  margin-left: 10px;
}
</style>
